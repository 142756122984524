import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'; 
import hamburgerIcon from '/hamburgerhover.png';
import '../HamburgerMenu.css';

const HamburgerMenu = () => {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isHeaderHidden, setIsHeaderHidden] = useState(false);
  const location = useLocation(); 

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY || document.documentElement.scrollTop;
      setIsHeaderHidden(scrollTop > 0); 
    };

    window.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  useEffect(() => {
    setIsMenuVisible(false);
  }, [location]); 

  const handleMenuItemClick = () => {
    setIsMenuVisible(false); 
  };

  return (
    <div className="hamburger-container">
      <img
        className={`hamburger-icon ${isHeaderHidden ? 'visible' : ''}`}
        src={hamburgerIcon}
        alt="Menu"
        onClick={() => setIsMenuVisible(!isMenuVisible)}
      />
      {isMenuVisible && (
        <div className={`hamburger-menu ${isMenuVisible ? 'visible' : ''}`}>
          <ul>
            <li><Link to="/Welcome" onClick={handleMenuItemClick}>Home</Link></li>
            <li><Link to="/Capabilities" onClick={handleMenuItemClick}>Capabilities</Link></li>
            <li><Link to="/ContactUs" onClick={handleMenuItemClick}>Contact</Link></li>
            <li><Link to="/AboutUs" onClick={handleMenuItemClick}>Our Story</Link></li> {/* Added Our Story */}
          </ul>
        </div>
      )}
    </div>
  );
};

export default HamburgerMenu;
