import React from 'react';
import { useLocation } from 'react-router-dom';
import '../Phone.css';

const Phone = () => {
  const location = useLocation();

  if (location.pathname === '/Welcome') {
    return null;
  }

  return (
    <div className="phone-icon">
      <a href="tel:+3309426357" rel="noopener noreferrer">
        <img src="/Phone.png" alt="Phone" className="phone-image" />
      </a>
    </div>
  );
};

export default Phone;
