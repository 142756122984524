import React, { useEffect, useRef, useState } from 'react';
import Carousel from '../components/ImageSlider';
import '../Capabilities.css'; // Ensure this file contains necessary styles
import ScrollToTopButton from '../components/ScrollToTopButton';
import ScrollDownButton from '../components/ScrollDownButton'; // Adjust the path if necessary
import anime from 'animejs/lib/anime.es.js'; // Import animejs

const Capabilities = () => {
  const carouselRef = useRef(null);
  const paragraphRef = useRef(null);
  const [animationStarted, setAnimationStarted] = useState(false);

  useEffect(() => {
    if (!animationStarted && paragraphRef.current) {
      const text = paragraphRef.current.textContent;
      paragraphRef.current.innerHTML = text
        .split('')
        .map(char => `<span class="letter">${char}</span>`)
        .join('');

      anime.timeline({ loop: false })
        .add({
          targets: '.letter',
          opacity: [0, 1],
          easing: "easeInOutQuad",
          duration: 50,
          delay: (el, i) => 30 * (i + 1),
        });

      setAnimationStarted(true);
    }
  }, [animationStarted]);

  const handleScrollDown = () => {
    if (carouselRef.current) {
      const carousel = carouselRef.current;
      const carouselRect = carousel.getBoundingClientRect();
      const viewportHeight = window.innerHeight;
      const offset = -100;
      const scrollPosition = window.scrollY + carouselRect.top - (viewportHeight / 2 - carouselRect.height / 2) - offset;

      window.scrollTo({
        top: scrollPosition,
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="Capabilities">
      <p ref={paragraphRef} className="page-content">
        CENTER STREET TECHNOLOGIES UTILIZES LARGE-SCALE 3-D PRINTING, CNC MACHINING, AND 3-D GEOMETRIC SCANNING TO BRIDGE THE GAP BETWEEN ADVANCED DESIGN AND MANUFACTURING. OUR ADVANCED MANUFACTURING PROCESS IS DRIVEN BY A DIGITAL TWIN ARCHITECTURE THAT ENABLES MODEL-BASED SIMULATIONS AT SCALE.
      </p>

      <div ref={carouselRef} id="carousel">
        <Carousel />
      </div>

      <ScrollDownButton onClick={handleScrollDown} />
      <ScrollToTopButton />
    </div>
  );
};

export default Capabilities;
