import React, { useEffect, useRef } from 'react';
import anime from 'animejs/lib/anime.es.js';
import downButtonImage from '/downbutton.png'; 

const ScrollDownButton = ({ onClick }) => {
  const buttonRef = useRef(null);

  useEffect(() => {
    const img = new Image();
    img.src = downButtonImage;

    img.onload = () => {
      anime({
        targets: buttonRef.current,
        opacity: [0, 1],
        easing: "easeInOutQuad",
        duration: 600,
        delay: 8500, 
      });
    };
  }, []);

  return (
    <button
      className="scroll-down-button"
      onClick={onClick}
      ref={buttonRef}
      style={{ opacity: 0, position: 'absolute', bottom: '20px', left: '50%', transform: 'translateX(-50%)' }} // Start with opacity 0 and positioned off-screen
    >
      <img src={downButtonImage} alt="Scroll Down" />
    </button>
  );
};

export default ScrollDownButton;
