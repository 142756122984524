import React, { useState, useRef, useEffect } from 'react';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import '@splidejs/react-splide/css';
import { AutoScroll } from '@splidejs/splide-extension-auto-scroll';
import '../Slider.css';

const topics = [
  'DFAM', 'SCAN', 'ENGINEER', '5-AXIS MACHINING', 'ANALYSIS',
  'PROTOTYPING', '3-D PRINT', 'MATERIALS DEVELOPMENT'
];

const topicImages = {
  'DFAM': ['./dfam4.png', './dfam5.png', './dfam6.png', './dfam7.png'],
  'SCAN': ['./uuv.jpg', './uuv2.jpg', `./Scan3.jpg`,  `grale3.png`],
  'ENGINEER': ['./engineer1.PNG', './watersol.png', './theramls.png' , `./theramals2.png`],
  '5-AXIS MACHINING': ['./machining.jpg' ,'./SS3.jpg', `./5axis.png`],
  'ANALYSIS': ['./scan2.jpg', './thermal.jpg',  './DIC.png','./Hitch.JPG',`./ND.JPG`],
  'PROTOTYPING': ['team_mash.jpg','./proto2.png', './proto1.png' ,  `./ptype.png` , './3dprintingplane.PNG', './mash.png' ],
  '3-D PRINT': ['./uav.jpg', './printtool.PNG', './3dprintingplane.PNG' , `./machine.jpg`],
  'MATERIALS DEVELOPMENT': ['./testing.PNG', './testing_3.PNG', './materialsdev2.jpg' , './tensil.jpg']
};

const Carousel = () => {
  const [selectedTopic, setSelectedTopic] = useState('PROTOTYPING'); // Default to 'PROTOTYPING'
  const [loading, setLoading] = useState(false); // Load State
  const splideRef = useRef(null);

  const handleCategoryClick = (topic) => {
    setLoading(true); // loading true if new topic is selected 
    setSelectedTopic(topic);
  };

  useEffect(() => {
    if (splideRef.current && splideRef.current.splide) {
      splideRef.current.splide.refresh();
    }

    
    const timer = setTimeout(() => {
      setLoading(false); 
    }, 500); 

    return () => clearTimeout(timer);
  }, [selectedTopic]);

  return (
    <div className="carousel-container">
      <h1 className="carousel-title">{selectedTopic || 'Select a Topic'}</h1>

      {loading ? (
        <div className="loading-spinner">Loading...</div> // Loading indicator
      ) : (
        selectedTopic && (
          <Splide
            ref={splideRef}
            options={{
              type: 'loop',
              perPage: 2,
              perMove: 1,
              interval: 4000,
              arrows: false,
              pagination: false,
              gap: '20px',
              breakpoints: {
                1200: {
                  perPage: 1,
                  autoScroll: {
                    speed: 1.5 
                  }
                },
                700: {
                  perPage: 1,
                  autoScroll: {
                    speed: 1.5 // Adjust scroll speed for even smaller screens
                  }
                }
              },
              autoScroll: {
                speed: 3, // Default scroll speed for larger screens
                autoStart: true,
                pauseOnHover: false,
                rewind: false
              }
            }}
            extensions={{ AutoScroll }}
          >
            {topicImages[selectedTopic].map((image, index) => (
              <SplideSlide key={index}>
                <img src={image} alt={`Image ${index + 1}`} />
              </SplideSlide>
            ))}
          </Splide>
        )
      )}

      <div className="categories">
        {topics.map((topic, index) => (
          <span
            key={index}
            className={selectedTopic === topic ? 'active' : ''}
            onClick={() => handleCategoryClick(topic)}
          >
            {topic}
          </span>
        ))}
      </div>
    </div>
  );
};

export default Carousel;
