import React, { useState, useEffect } from 'react';
import '../ScrollToTopButton.css'; 

const ScrollToTopButton = () => {
  const [showButton, setShowButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) { // Show button when scrolled more than 300px
      setShowButton(true);
    } else {
      setShowButton(false);
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth', // Smooth scrolling for a better experience
    });
  };

  return (
    <button 
      className={`scroll-to-top ${showButton ? 'show' : ''}`} 
      onClick={scrollToTop}
    >
      <img src="/ScrollToTop.png" alt="Scroll to Top" />
    </button>
  );
};

export default ScrollToTopButton;
